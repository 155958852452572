
export default [
  // {
  //   title: "Dashboard",
  //   route: "dashboard",
  //   icon: "HomeIcon",
  // },

  {
    title: "Notifikasi",
    route: "notifikasi",
    icon: "BellIcon",
    action: 'manage',
    resource: 'notificationSubject',

    
  },

  {
    title: "Customer",
    icon: "UserIcon",
    action: 'manage',
    resource: 'customerSubject',
    children: [
      {
        title: "Kavling",
        route: "kavling",
        action: 'manage',
        resource: 'customerSubject',
      },
      {
        title: "Blok",
        route: "blok",
        action: 'manage',
        resource: 'customerSubject',
      },
      {
        title: "Kepemilikan",
        route: "kepemilikan",
        action: 'manage',
        resource: 'customerSubject',
      },
      {
        title: "Penagihan",
        route: "penagihan",
        action: 'manage',
        resource: 'customerSubject',
      },
      {
        title: "PPJB",
        route: "ppjb",
        action: 'manage',
        resource: 'customerSubject',
      },
    ],
  },

  {
    title: "Aset",
    icon: "FileIcon",
    action: 'manage',
    resource: 'assetSubject',
    children: [
      {
        title: "Data Aset",
        route: "aset",
        action: 'manage',
        resource: 'assetSubject',
      }
    ],
  },

  {
    title: "Employee",
    icon: "UsersIcon",
    children: [
      {
        title: "Data Karyawan",
        route: "karyawan",
        action: 'manage',
        resource: 'employeeSubject',
      },
      {
        title: "Data Penggajian",
        route: "penggajian",
        action: 'manage',
        resource: 'employeeSubject',
      },
    ],
  },

  {
    title: "Transaksi",
    icon: "CreditCardIcon",
    children: [
      {
        title: "Data Pemasukan",
        route: "pemasukan",
        action: 'manage',
        resource: 'transactionIncomeSubject',
      },
      {
        title: "Data Pengeluaran",
        route: "pengeluaran",
        action: 'manage',
        resource: 'transactionExpensesSubject',
      }
    ],
  },

  {
    title: "Legalitas",
    icon: "FolderIcon",
    action: 'manage',
    resource: 'legalSubject',
    children: [
      {
        title: "Legal",
        route: "legal",
        action: 'manage',
        resource: 'legalSubject',
      }
    ],
  },

  {
    title: "User",
    route: "user",
    icon: "UserPlusIcon",
    action: 'manage',
    resource: 'userSubject',
  },

];
