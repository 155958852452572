<template>
  <layout-vertical>
    <router-view />

    <!-- <app-customizer v-if="showCustomizer" slot="customizer" /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
// import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  beforeMount() {
    // this.checkLogin();
  },
  methods: {
    checkLogin() {
      return new Promise(() => {
        axios
          .get("/v1/auth/my-token")
          .then((res) => {})
          .catch((error) => {
            // if (error.response.status == 401) {
            //   this.$router.push("/login");
            // } else {
            //   this.$router.push("/login");
            // }

            this.$router.push({ name: "auth-login" });
          });
      });
    },
  },
};
</script>
